<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            style="background-color: transparent"
                            @input="getdata($event)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Type</label>
                          <v-select
                            v-model="type"
                            label="name"
                            placeholder="None"
                            :options="[
                              { name: 'All', id: 'all' },
                              'journal',
                              'Contra',
                              'Credit Note',
                              'Debit Note',
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Report Type</label>
                          <v-select
                            v-model="reporttype"
                            placeholder="None"
                            :options="['Bill Wise', 'Item Wise']"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data" :columns="fields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import ReportTable from "../../../components/ReportTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import vSelect from "vue-select";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable,
  },
  data() {
    return {
      fromdate: "",
      enddate: "",
      type: "",
      reporttype: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "id",
          label: "S.R. No",
          hidden: true,
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "date",
          label: "Date",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "type",
          label: "Type",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "narration",
          label: "Narration",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "total",
          label: "Total",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
      ],
    };
  },
  methods: {
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData(tableData) {
      const data = {
        startdate: this.startdate,
        enddate: this.enddate,
        type: this.type && this.type.id ? this.type.id : this.type,
        reporttype: this.reporttype,
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/vouchereport`,
      }).then((response) => {
        this.data = response.data.data;
        this.data.map((item) => {
          item.total = parseFloat(0);
          item.VoucherItem &&
            item.VoucherItem.map((sub) => {
              item.total += parseFloat(sub.amount);
            });
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
